<template>
    <q-markup-table>
        <thead>
            <tr>
                <th
                    class="text-left"
                    style="width: 25%"
                >
                    <q-skeleton
                        animation="blink"
                        type="text"
                    />
                </th>
                <th
                    class="text-right"
                    style="width: 25%"
                >
                    <q-skeleton
                        animation="blink"
                        type="text"
                    />
                </th>
                <th
                    class="text-right"
                    style="width: 25%"
                >
                    <q-skeleton
                        animation="blink"
                        type="text"
                    />
                </th>
                <th
                    class="text-right"
                    style="width: 25%"
                >
                    <q-skeleton
                        animation="blink"
                        type="text"
                    />
                </th>
            </tr>
        </thead>

        <tbody>
            <tr
                v-for="n in 5"
                :key="n"
            >
                <td class="text-left">
                    <q-skeleton
                        animation="blink"
                        type="text"
                        width="60%"
                    />
                </td>
                <td class="text-center">
                    <q-skeleton
                        animation="blink"
                        type="text"
                        width="60%"
                    />
                </td>
                <td class="text-right">
                    <q-skeleton
                        animation="blink"
                        type="text"
                        width="60%"
                    />
                </td>
                <td class="text-right">
                    <q-skeleton
                        animation="blink"
                        type="text"
                        width="60%"
                    />
                </td>
            </tr>
        </tbody>
    </q-markup-table>
</template>

<script>
export default {
    name: 'QTableSkeleton'
}
</script>

<style>

</style>
